.mat-mdc-header-cell {
  color: $color-2 !important;
  font-weight: bold;
}
.mat-mdc-table {
  box-shadow: none !important;
}

.mat-mdc-header-row {
  height: 32px !important;
}
