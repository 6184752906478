.mdc-linear-progress {
  height: 24px;

  .mdc-linear-progress__buffer-bar {
    background-color: $color-14;
  }

  .mdc-linear-progress__bar-inner {
    height: 24px;
    background-color: #b0eb82;
    border-color: #b0eb82;
  }

  .mdc-linear-progress__buffer {
    height: unset;
  }

  .mdc-linear-progress__bar {
    height: unset;
  }

  &.red {
    .mdc-linear-progress__bar-inner {
      background-color: #eb8282;
      border-color: #eb8282;
    }
  }
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: $color-5 !important;
}

.mat-mdc-progress-spinner {
  width: 44px !important;
}
