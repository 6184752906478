// -colors list
$color-1: #eff6ff;
$color-2: #797979;
$color-3: #0064ff;
$color-4: #64748b;
$color-5: #404d61;
$color-6: #b3261e;
$color-7: #e9a905;
$color-8: #0591bd;
$color-9: #6f0096;
$color-10: #55a119;
$color-11: #e96505;
$color-12: #e9052e;
$color-13: #205e0b;
$color-14: #ffffff;
$color-15: #b38e2f;
$color-15: #b01ee5;
$color-16: #74aaeb;
$color-17: #38a252;
$color-18: #e68d14;
$color-19: #dcc44a;
$color-20: #e66c6c;
$color-21: #1f87fe;
$color-22: #b8b8b8;
$color-23: #e6e8ec;
$color-24: #475569;
$color-25: #b9b9b9;
$color-26: #eaeaea;
$color-27: #283851;
$color-28: #18b53e;
$color-29: #6c0590;

// ---Layout
$color-text: $color-5;
$color-text-secondary: $color-4;

//MENU
$color-main-menu: $color-14;

//HEADER
$color-header: $color-14;
$color-header-searchbar: $color-1;

// ---Categories
$color-electricity: $color-7;
$color-water: $color-8;
$color-gas: $color-9;
$color-solar: $color-10;
$color-electric-station: $color-11;
$color-traffic-light: $color-12;
$color-trees: $color-13;
$color-c02: $color-5;
$color-gasoline: $color-15;
$color-driven: $color-6;

// ---Building Systems
$color-mechanicalSystems: $color-6;
$color-lightningSystems: $color-7;
$color-smartMeterSolar: $color-10;
$color-chargingStation: $color-7;

// ---Alert

$color-alert-building-systems: $color-mechanicalSystems;
$color-alert-charging-stations: $color-chargingStation;
$color-alert-renewables: $color-solar;
$color-alert-street-lights: $color-19;
$color-alert-utilities: $color-19;
$color-alert-water-leak: $color-water;

// ---Form elements
$color-login-btn: $color-3;
$color-primary-btn: $color-5;
$color-secondary-btn: $color-5;

// ---SELECT
$color-background-select: $color-1;
$color-border-select: $color-5;

// ---BUTTON
$color-background-button: $color-5;
$color-font-button: $color-14;

// ---CHIP
$color-background-chip: $color-1;
$color-background-chip-active: $color-5;
$color-font-chip-active: $color-14;
