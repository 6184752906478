@use '@angular/material' as mat;

//Common
@import './assets/scss/comon/mixin';
@import './assets/scss/comon/color';
@import './assets/scss/comon/font';
@import './assets/scss/comon/variables';

//Material surcharge
@import './assets/scss/material/theme';

//Layout
@import './assets/scss/layout/base';

/* Ancien icon. Refacto necessaire pour rempacer par les nouveaux */
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

/* Nouveaux icons */
@import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0';

body {
  margin: 0px;
}

html,
body {
  height: 100%;
}

.dense-3 {
  @include mat.all-component-densities(-3);
}

.dense-4 {
  @include mat.all-component-densities(-4);
}

.dense-5 {
  @include mat.all-component-densities(-5);
}

.dense-7 {
  @include mat.all-component-densities(-7);
}

.dense-8 {
  @include mat.all-component-densities(-8);
}

.dense-9 {
  @include mat.all-component-densities(-9);
}

.dense-10 {
  @include mat.all-component-densities(-10);
}
