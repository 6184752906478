

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: $font-1;
  font-weight: $font-weight-400;
  margin: 0;
  font-size: $font-size-body;
  color: $color-text;
}

// TITLE
h1 {
  font-family: $font-h1;
  font-size: $font-size-h1;
}

// MAT ICON
.mat-icon {
  width: auto !important;
  height: auto !important;
}

.icon-Electricity {
  color: $color-electricity;
}

.icon-Water {
  color: $color-water;
}

.icon-Gas {
  color: $color-gas;
}

.icon-Solar {
  color: $color-solar;
}

//BTN ROUNDED
.btn {
  cursor: pointer;

  &.rounded {
    background-color: $color-14;
    color: $color-text;

    &.small {
      height: 24px;
      width: 24px;
      border-radius: 50%;
    }

    &.medium {
      height: 34px;
      width: 34px;
      line-height: 34px;
      border-radius: 50%;
    }

    span {
      vertical-align: middle;
      margin-left: 1px;
    }
  }
}
