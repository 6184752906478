.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before{
    background-color: red;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after{
    background: $color-4 !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after{
    background: $color-5 !important;
}