.mat-raised-button.mat-primary {
  background-color: $color-login-btn;
}

//BUTTON BASE
.mat-mdc-button.mat-mdc-button-base {
  background-color: $color-background-button;
  color: $color-font-button !important;
  border-radius: 20px;
  height: 40px;
  padding: 8px 16px;
}
