.mat-button-toggle-group {
  border-radius: 25px;
  border-color: #64748b !important;

  .mat-button-toggle {
    font-family: $font-1;
    font-size: 14px;
    background: #eff6ff;
    border-color: #64748b !important;

    &:hover {
      background: $color-2;
    }

    &.mat-button-toggle-checked {
      background-color: $color-3;
      color: #ffffff;
    }

    .mat-button-toggle-label-content {
      padding: 0 16px;
      line-height: 36px;
    }
  }
}

//Toggle Styled

::ng-deep .mat-button-toggle {
  background-color: transparent !important;
  color: $color-5 !important;
}

::ng-deep .mat-button-toggle:hover {
  background-color: transparent;
}

::ng-deep .mat-button-toggle-checked {
  background-color: $color-5 !important;
  color: $color-1 !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
  background-color: $color-22 !important;
}

//CheckBox

::ng-deep .mat-mdc-radio-button .mdc-radio {
  .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: $color-5 !important;
  }

  .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $color-5;
  }
}
