@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap');

$font-1: 'Archivo', sans-serif;
$font-2: 'Jost', sans-serif;

$font-weight-400: 400;
$font-weight-600: 600;
$font-weight-700: 700;

// Font size
$font-size-medium: 16px;
$font-size-small: 14px;
$font-size-body: $font-size-medium;

// TITLE
$font-h1: $font-1;
$font-size-h1: 32px;

//
$font-family-breadcrumb: $font-2;
$font-weight-breadcrumb: $font-weight-400;
$font-weight-breadcrumb-current: $font-weight-700;
