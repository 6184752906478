$cubicBezier: cubic-bezier(0.42, 0, 0.58, 1);

/* Convert px font-size in rem

How to use ?
@include font-size(16px);

*/
$legacy-rem: true;
@mixin rem($property, $value, $base: $font-size-body) {
    @if $legacy-rem {
        #{$property}: $value;
    }

    #{$property}: $value / $base * 1rem;
}


/* animate element list

How to use ?
@include apparition-cards(duree, delay, numberOfElements, finalOpacity);

*/
@mixin apparition-cards($duree: 0.2s, $delai: 0.2s, $elements: 10, $opacity: 1) {

  @for $i from 1 through $elements {
    &:nth-child(#{$i}) {
      animation: apparition-card $duree $cubicBezier ($delai * $i) both;
    }
  }

  @keyframes apparition-card {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: $opacity;
      transform: translateY(0);
    }
  }
}