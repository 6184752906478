//SELECT - SELECT DATE RANGE
.mat-mdc-form-field.mat-mdc-form-field-type-mat-select,
.mat-mdc-form-field-type-mat-date-range-input {
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  .mdc-text-field {
    border-radius: 4px;
    background-color: $color-background-select;

    .mat-datepicker-toggle-active {
      color: $color-3;
    }
  }

  .mdc-floating-label {
    color: $color-text;
  }

  .mdc-line-ripple {
    display: none;
  }

  &.mat-focused {
    .mdc-floating-label--float-above,
    .mat-mdc-select-arrow {
      color: $color-text;
    }
  }
}

:host ::ng-deep mdc-text-field--outlined mdc-text-field--focused {
  border: none;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background-color: $color-5;
}

// PANEL SELECT
.mat-mdc-select-panel {
  background-color: $color-background-select;
  border-radius: 4px;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

:host ::ng-deep .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
  background-color: white;
}

// SELECT DATE RANGE
.mat-mdc-form-field-type-mat-date-range-input {
  .mdc-text-field {
    background-color: $color-14;
  }
}

.mat-datepicker-content-container {
  .mat-calendar-header {
    .mat-mdc-button {
      color: $color-14;

      svg {
        color: $color-14;
      }
    }
  }
  .mat-calendar-content {
    .mat-calendar-body-selected {
      background-color: $color-3;
    }

    .mat-calendar-body-in-range::before,
    .mat-calendar-body-in-range::after {
      background: $color-1;
    }

    .mat-calendar-body-cell:hover .mat-calendar-body-cell-content {
      background-color: $color-1;
    }
  }
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $color-1;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $color-1;
}

// MASQUE ERREUR
.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: $color-3;
}
