.mat-mdc-form-field.mat-mdc-form-field-type-mat-input {
  font-family: $font-1 !important;

  .mdc-text-field {
    // -----BASE TEXT FIELD
    .mat-mdc-form-field-focus-overlay {
      display: none;
    }

    .mat-mdc-floating-label {
      font-family: $font-1 !important;
    }

    &:not(.mdc-text-field--disabled) {
      .mdc-floating-label {
        color: $color-text;
      }
    }

    // -----TEXT FIELD FILL APPARANCE
    &--filled:not(.mdc-text-field--disabled) {
      background-color: $color-1;
      border: 1px solid $color-25 !important;

      .mdc-line-ripple::before,
      .mdc-line-ripple::after {
        display: none;
      }
    }
  }
}
